<script setup lang="ts">
import DefaultLayout from "./default.vue";
import BranchPageBanner from "~/components/BranchPageBanner.vue";

const route = useRoute();

const title = computed(() => route.meta.page?.title || "");

const { data: branch } = useCurrentBranch();

const isReady = ref(false);
provide("layout", {
  isReady,
});

onMounted(() => {
  isReady.value = true;
});
</script>

<template>
  <DefaultLayout>
    <div class="bg-gray">
      <BranchPageBanner :branch="branch" :title="title" />
      <div id="top-bar"></div>
      <CommonContainer class="py-[--y-margin]">
        <div class="grid grid-cols-12">
          <AdminSidebar class="order-1 col-span-12 md:-order-1 md:col-span-3" :branch="branch" />
          <div class="col-span-12 md:col-span-9">
            <slot />
          </div>
        </div>
      </CommonContainer>
    </div>
  </DefaultLayout>
</template>
